import React, { useEffect, useState } from "react"
import Layout from "../../components/_App/layout"
import SEO from "../../components/_App/seo"
import NavbarNew from "../../components/NavbarNew"
import Footer from "../../components/_App/Footer"
import MeetingImage from "../../assets/images/meeting.jpg"
import moment from "moment"
import { navigate } from "gatsby"

const ConfirmationMeeting = () => {
  const [startTime, setStartTime] = useState("")

  useEffect(() => {
    if (typeof window !== "undefined") {
      let urlParams = new URLSearchParams(window.location.search)
      const paramValue = urlParams.get("event_start_time")
      console.log(paramValue)
      if (paramValue === null) {
        navigate("/404")
      }
      setStartTime(paramValue)
    }
  }, [])
  return (
    <Layout>
      <SEO
        title="Tarkashilpa - Web and Mobile Application Development Company"
        description="Tarkashilpa is a web and mobile application development company with knowledge of latest technologies and innovation. We follow strict app development standards."
        keywords="Web Application Development Services Company Mobile Application Development Services Company"
        url={``}
        ogTwitterCardType="summary"
      />
      <NavbarNew />
      <div className="meeting-container">
        <center>
          <img
            src={MeetingImage}
            alt="Meeting Confirmed"
            className="col-12 col-lg-7"
          />
          <h2 style={{ width: "90%" }}>
            Thank you for scheduling a meeting with us!
          </h2>
          <p style={{ width: "90%" }}>
            We look forward to meeting with you at the scheduled on{" "}
            <b>{moment(startTime).format("Do MMM YYYY")}</b> at{" "}
            <b>{moment(startTime).format("hh:mm:ss a")}</b>.
          </p>
        </center>
      </div>
      <Footer />
    </Layout>
  )
}

export default ConfirmationMeeting
